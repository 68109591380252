/*
 * Copyright 2023 Nordeck IT + Consulting GmbH
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

export {
  CURSOR_UPDATE_MESSAGE,
  isValidCursorUpdateMessage,
} from './cursorUpdate';
export type { CursorUpdate } from './cursorUpdate';
export {
  DOCUMENT_UPDATE_MESSAGE,
  isValidDocumentUpdateMessage,
} from './documentUpdate';
export type { DocumentUpdate } from './documentUpdate';
export { FOCUS_ON_MESSAGE, isValidFocusOnMessage } from './focusOn';
export type { FocusOn } from './focusOn';
export {
  PRESENT_SLIDE_MESSAGE,
  isValidPresentSlideMessage,
} from './presentSlide';
export type { PresentSlide } from './presentSlide';
