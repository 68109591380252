/*
 * Copyright 2023 Nordeck IT + Consulting GmbH
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

export {
  ElementOverridesProvider,
  createResetElementOverrides,
} from './ElementOverridesProvider';
export type { ElementOverrideUpdate } from './ElementOverridesProvider';
export { useElementOverride } from './useElementOverride';
export { useElementOverrides } from './useElementOverrides';
export { useSetElementOverride } from './useSetElementOverride';
