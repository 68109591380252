/*
 * Copyright 2022 Nordeck IT + Consulting GmbH
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useActiveWhiteboardInstance } from '../../state';

export function AddSlideButton() {
  const { t } = useTranslation();
  const whiteboardInstance = useActiveWhiteboardInstance();

  const handleAddSlide = useCallback(() => {
    const slideId = whiteboardInstance.addSlide();
    whiteboardInstance.setActiveSlideId(slideId);
  }, [whiteboardInstance]);

  const title = t('slideOverviewBar.addSlide', 'Add slide');

  return (
    <Button
      fullWidth
      aria-label={title}
      onClick={handleAddSlide}
      startIcon={<AddIcon />}
      variant="outlined"
    >
      {title}
    </Button>
  );
}
